<template>
  <form-modal
    :title="_title"
    ref="formmodal"
    @save="handleSubmit"
    @cancel="cancel"
  >
    <b-row>
      <form-input
        label="ชื่อรายการ"
        v-model="form.name"
        v-validate="`required`"
        data-vv-as="ชื่อรายการ"
        :col="12"
      />
      <form-input
        label="รายละเอียด"
        v-model="form.detail"
        data-vv-as="รายละเอียด"
        :col="12"
      />
      <form-input
        label="ชื่อกฏ (ENG)"
        v-model="form.rule_name"
        v-validate="`required`"
        data-vv-as="ชื่อกฏ (ENG)"
        :col="12"
      />
      <form-input-currency
        label="ค่าต่างๆ"
        v-model="form.rule_value"
        v-validate="`required`"
        data-vv-as="ค่าต่างๆ"
        :col="12"
      />
    </b-row>
  </form-modal>
</template>
<script>
import { mapMutations, mapState } from "vuex"
export default {
  data() {
    return {
      form: "",
      dummy_data: "",
    }
  },
  computed: {
    ...mapState("PackageForm", ["form_package_list"]),
    _title() {
      return this.form.package_data_id ? "แก้ไขกฏ" : "เพิ่มกฏ"
    },
  },
  created() {
    this.form = this.cp(this.form_package_list)
  },
  methods: {
    ...mapMutations("PackageForm", [
      "PUT_PACKAGE_LIST_DATAS",
      "EDIT_PACKAGE_LIST_DATAS",
    ]),
    show(data = "") {
      this.clearForm()
      if (data) {
        this.dummy_data = this.cp(data)
        this.form = this.cp(data)
      } else {
        this.dummy_data = ""
      }
      this.switch("show")
    },
    async handleSubmit() {
      try {
        const validatetor = await this.$validator.validateAll()
        if (!validatetor) {
          this.alertValidate()
          return ""
        }
        if (this.dummy_data) {
          this.EDIT_PACKAGE_LIST_DATAS({
            form: this.cp(this.form),
            dummy_data: this.dummy_data,
          })
        } else {
          this.PUT_PACKAGE_LIST_DATAS(this.cp(this.form))
        }
        this.switch("hide")
      } catch (error) {
        console.error(error)
        return ""
      }
    },
    cancel() {
      this.clearForm()
    },
    clearForm() {
      const form = this.form
      Object.keys(form).forEach((fill) => [(form[fill] = "")])
      this.switch("hide")
    },
    switch(type) {
      type === "show"
        ? this.$refs.formmodal.show()
        : this.$refs.formmodal.hide()
    },
  },
}
</script>
